*{
  -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body, #__next {
  margin: 0;
  min-width: 100vw;
  min-height: 100dvh;
  font-family: "Satoshi-Variable";
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  -webkit-tap-highlight: transparent;
  @media only screen and (min-width: 600px){
    background-color: #F2F3F7;
  }
  
}
